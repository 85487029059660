<template>
  <div class="mt-4 mx-3 row white-box px-3 py-4">
    <div class="white-box-top"></div>
    <div class="col-md-4 col-lg-2">
      <nav class="left-nav">
        <button
          class="btn w-100 text-start"
          v-on:click="filterCountry = null"
          :class="filterCountry === null ? 'btn-primary' : 'btn-simple'"
        >
          {{ $t("warehouses.viewAll") }}
        </button>
        <button
          class="btn w-100 text-start"
          :class="filterCountry === c.id ? 'btn-primary' : 'btn-simple'"
          v-for="c in activeCountries.filter(
            (e) => warehouseCountries.indexOf(e.id) > -1
          )"
          :key="c.id"
          v-on:click="filterCountry = c.id"
        >
          {{ c.name }}
        </button>
      </nav>
    </div>
    <div class="col-md-8 col-lg-10">
      <h2>
        {{ $t("warehouses.warehouses") }}
      </h2>
      <one-by-one-table
        v-model:rows="rows"
        :items="
          warehouses === undefined
            ? undefined
            : warehouses.filter(
                (e) =>
                  e.address?.country_id === filterCountry ||
                  filterCountry === null
              )
        "
        type="warehouse"
        :show-edit="true"
        v-on:edit="showEditModal"
        v-on:addbtn="showAddModal"
        v-on:delete="showDeleteModal"
      />
    </div>
  </div>
  <div
    ref="addWarehouseModal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div
      class="
        modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable
      "
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{
              $t(`warehouses.warehouse`, {
                expr: editWarehouse ? "Edit" : "Add new",
              })
            }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            :aria-label="$t(`orderChange.close`)"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-3 mt-2">
              <button
                class="btn w-100 d-block text-start"
                v-on:click="openTab = 'basic'"
                :class="{
                  'btn-primary': openTab === 'basic',
                  'btn-simple': openTab !== 'basic',
                }"
              >
                {{ $t("warehouses.basics") }}
              </button>
              <button
                class="btn w-100 d-block text-start"
                v-on:click="openTab = 'outgoing'"
                :class="{
                  'btn-primary': openTab === 'outgoing',
                  'btn-simple': openTab !== 'outgoing',
                }"
              >
                {{ $t("productHistory.outgoing") }}
              </button>
              <button
                class="btn w-100 d-block text-start"
                v-on:click="openTab = 'optimalStock'"
                :class="{
                  'btn-primary': openTab === 'optimalStock',
                  'btn-simple': openTab !== 'optimalStock',
                }"
              >
                {{ $t("warehouses.optimalStock") }}
              </button>
              <button
                class="btn w-100 d-block text-start"
                v-on:click="openTab = 'shop'"
                :class="{
                  'btn-primary': openTab === 'shop',
                  'btn-simple': openTab !== 'shop',
                }"
              >
                {{ $t("warehouses.shop") }}
              </button>
              <button
                class="btn w-100 d-block text-start"
                v-if="newWarehouse.fulfilment_app_id"
                v-on:click="openTab = 'fulfillment'"
                :class="{
                  'btn-primary': openTab === 'fulfillment',
                  'btn-simple': openTab !== 'fulfillment',
                }"
              >
                {{ $t("warehouses.fulfillment") }}
              </button>
              <button
                v-if="newWarehouse.id"
                class="btn w-100 d-block text-start"
                v-on:click="openTab = 'download'"
                :class="{
                  'btn-primary': openTab === 'download',
                  'btn-simple': openTab !== 'download',
                }"
              >
                {{ $t("warehouses.downloadXlsx") }}
              </button>
              <button
                v-if="newWarehouse.id"
                class="btn w-100 d-block text-start btn-simple"
                v-on:click="downloadBarcodes(newWarehouse.id)"
              >
                {{ $t("warehouses.printBarcodes") }}
              </button>
            </div>
            <div class="col-9 mt-2 text-start custom-input">
              <div class="row" v-if="openTab === 'basic'">
                <div class="col-12 mb-3 whitebg-input">
                  <label class="form-label bold-12 text-gray">
                    {{ $t("products.unitName") }}
                  </label>
                  <input class="form-control" v-model="newWarehouse.name" />
                </div>
                <div class="col-4 mb-3 whitebg-input">
                  <label class="form-label bold-12 text-gray">
                    {{ $t("warehouses.unitPrefix") }}
                  </label>
                  <input class="form-control" v-model="newWarehouse.prefix" />
                </div>
                <div class="col-8 mb-3 whitebg-input">
                  <label class="form-label bold-12 text-gray">
                    {{ $t("warehouses.unitType") }}
                  </label>
                  <v-select
                    :options="[
                      { id: 1, name: 'Distribution', image: 'box' },
                      { id: 2, name: 'Production', image: 'scissor' },
                      { id: 3, name: 'Retail', image: 'delivery-info' },
                    ]"
                    v-model="newWarehouse.type"
                    label="name"
                    :placeholder="$t(`warehouses.selectType`)"
                    :reduce="(c) => c.id"
                  >
                    <template v-slot:selected-option="option">
                      <BaseIcon
                        name="shop"
                        v-if="option.id === 1 && newWarehouse.shop_id > 0"
                      />
                      <BaseIcon :name="option.image" v-else />
                      <span class="ms-3">
                        {{ option.name }}
                      </span>
                    </template>
                    <template v-slot:option="option">
                      <BaseIcon
                        name="shop"
                        v-if="option.id === 1 && newWarehouse.shop_id > 0"
                      />
                      <BaseIcon :name="option.image" v-else />
                      <span class="ms-3">
                        {{ option.name }}
                      </span>
                    </template>
                  </v-select>
                </div>
                <div class="mb-3 whitebg-input">
                  <label class="form-label bold-12 text-gray">
                    {{ $t("warehouses.company") }}
                  </label>
                  <v-select
                    :options="companies"
                    v-model="newWarehouse.company_id"
                    label="name"
                    :placeholder="$t(`warehouses.selectCompany`)"
                    :reduce="(c) => c.id"
                  />
                </div>
                <div class="mb-3 whitebg-input">
                  <label class="form-label bold-12 text-gray">{{
                    $t("orderChange.country")
                  }}</label>
                  <v-select
                    :options="activeCountries"
                    v-model="newWarehouse.country_id"
                    v-on:change="editAddress('country_id')"
                    label="name"
                    :placeholder="$t(`orderChange.selectCountry`)"
                    :reduce="(c) => c.id"
                  />
                </div>
                <div class="row mb-3 custom-input">
                  <div class="col-4 whitebg-input">
                    <label class="form-label bold-12 text-gray">{{
                      $t("orderChange.postCode")
                    }}</label>
                    <input
                      type="text"
                      v-model="newWarehouse.post_code"
                      v-on:change="editAddress('post_code')"
                      class="form-control"
                    />
                  </div>
                  <div class="col whitebg-input">
                    <label class="form-label bold-12 text-gray">{{
                      $t("orderChange.city")
                    }}</label>
                    <input
                      type="text"
                      v-model="newWarehouse.city"
                      v-on:change="editAddress('city')"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="row mb-3 whitebg-input">
                  <div class="col">
                    <label class="form-label bold-12 text-gray">{{
                      $t("warehouses.street")
                    }}</label>
                    <input
                      type="text"
                      v-model="newWarehouse.street"
                      v-on:change="editAddress('street')"
                      class="form-control"
                    />
                  </div>
                  <div class="col-5 whitebg-input">
                    <label class="form-label bold-12 text-gray">{{
                      $t("warehouses.house")
                    }}</label>
                    <input
                      type="text"
                      v-model="newWarehouse.house"
                      v-on:change="editAddress('house')"
                      class="form-control"
                    />
                  </div>
                  <div class="col-6 mb-3 whitebg-input">
                    <label class="form-label bold-12 text-gray">{{
                      $t("register.email")
                    }}</label>
                    <input class="form-control" v-model="newWarehouse.email" />
                  </div>
                  <div class="col-6 mb-3 whitebg-input">
                    <label class="form-label bold-12 text-gray">{{
                      $t("warehouses.phone")
                    }}</label>
                    <input class="form-control" v-model="newWarehouse.phone" />
                  </div>
                </div>
              </div>
              <div class="row" v-if="openTab === 'outgoing'">
                <div class="row mb-3 custom-input">
                  <div class="col-12 mb-3 whitebg-input">
                    <label class="form-label bold-12 text-gray">{{
                      $t("warehouses.defaultCurrency")
                    }}</label>
                    <v-select
                      v-model="newWarehouse.default_currency"
                      :options="currencies"
                      label="code"
                      :reduce="(c) => c.code"
                      :getOptionKey="(c) => c.code"
                      :placeholder="$t(`warehouses.selectACurrency`)"
                      :clearable="false"
                    />
                  </div>
                  <div class="col-12 mb-3 whitebg-input">
                    <label class="form-label bold-12 text-gray">{{
                      $t("warehouses.defaultPriceCorrection")
                    }}</label>
                    <div class="fix-end-input">
                      <input
                        type="number"
                        class="form-control"
                        v-model="newWarehouse.default_price_correction"
                        placeholder="100"
                      />
                      <span>%</span>
                    </div>
                  </div>
                  <div class="col-12 mb-3 whitebg-input">
                    <label class="form-label bold-12 text-gray">{{
                      $t("warehouses.defaultInvoiceMethod")
                    }}</label>
                    <v-select
                      v-model="newWarehouse.default_invoice_app"
                      :options="invoiceMethods"
                      :placeholder="$t(`warehouses.selectAMethod`)"
                      label="name"
                      :reduce="(c) => c.id"
                      :clearable="false"
                    />
                  </div>
                  <div class="col-12 mb-3 whitebg-input">
                    <label class="form-label bold-12 text-gray">{{
                      $t("warehouses.defaultDeliveryMethod")
                    }}</label>
                    <v-select
                      v-model="newWarehouse.default_delivery_method"
                      :options="deliveryMethods"
                      :placeholder="$t(`warehouses.selectAMethod`)"
                      label="name"
                      :reduce="(c) => c.id"
                      :clearable="false"
                    />
                  </div>
                </div>
              </div>
              <div class="row" v-if="openTab === 'optimalStock'">
                <div class="row mb-3 custom-input">
                  <div class="col-12 mb-3 whitebg-input">
                    <label class="form-label bold-12 text-gray">{{
                      $t("warehouses.incomingFee")
                    }}</label>
                    <div class="fix-end-input">
                      <input
                        type="number"
                        class="form-control"
                        v-model="newWarehouse.incoming_fee_in_base_currency"
                        placeholder="100"
                      />
                      <span>eur</span>
                    </div>
                  </div>
                  <div class="col-12 mb-3 whitebg-input">
                    <label class="form-label bold-12 text-gray">{{
                      $t("warehouses.storageFee")
                    }}</label>
                    <div class="fix-end-input">
                      <input
                        type="number"
                        class="form-control"
                        v-model="
                          newWarehouse.package_storage_unit_fee_in_base_currency
                        "
                        placeholder="100"
                      />
                      <span>eur</span>
                    </div>
                  </div>
                  <div class="col-12 mb-3 whitebg-input">
                    <label class="form-label bold-12 text-gray">{{
                      $t("warehouses.warehouseSize")
                    }}</label>
                    <div class="fix-end-input">
                      <input
                        type="number"
                        class="form-control"
                        v-model="newWarehouse.warehouse_size_in_units"
                        placeholder="100"
                      />
                      <span>cm<sup>3</sup></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" v-if="openTab === 'shop'">
                <div class="col-12 row mt-4">
                  <label class="cstm-check d-flex">
                    <span class="regular-12 text-black mx-4">
                      {{ $t("warehouses.thisWarehouseContainsAPhysicalShop") }}
                    </span>
                    <input
                      type="checkbox"
                      v-model="newWarehouse.shop.enabled"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div class="col-12 mb-3 whitebg-input">
                  <label class="form-label bold-12 text-gray">{{
                    $t("warehouses.shopName")
                  }}</label>
                  <input
                    class="form-control"
                    v-model="newWarehouse.shop.name"
                  />
                </div>
                <div class="col-6 mb-3 whitebg-input">
                  <label class="form-label bold-12 text-gray">{{
                    $t("register.email")
                  }}</label>
                  <input
                    class="form-control"
                    v-model="newWarehouse.shop.email"
                  />
                </div>
                <div class="col-6 mb-3 whitebg-input">
                  <label class="form-label bold-12 text-gray">{{
                    $t("warehouses.phone")
                  }}</label>
                  <input
                    class="form-control"
                    v-model="newWarehouse.shop.phone"
                  />
                </div>
                <div>
                  <label class="form-label bold-12 text-gray">{{
                    $t("warehouses.shopImage")
                  }}</label>
                  <ImageUpload
                    :id="newWarehouse.shop.id"
                    type="cover"
                    v-on:update="uploadImage"
                  />
                  <img
                    v-if="newWarehouse.shop.image"
                    :src="
                      this.mediaUrl +
                      '/' +
                      newWarehouse.shop.image.path +
                      '/360-' +
                      newWarehouse.shop.image.name +
                      '.webp'
                    "
                  />
                </div>
                <div class="col-6 mb-3 whitebg-input">
                  <label class="form-label bold-12 text-gray">{{
                    $t("warehouses.mondayOpen")
                  }}</label>
                  <input
                    class="form-control"
                    type="time"
                    v-model="newWarehouse.shop.open_monday"
                  />
                </div>
                <div class="col-6 mb-3 whitebg-input">
                  <label class="form-label bold-12 text-gray">{{
                    $t("warehouses.mondayClose")
                  }}</label>
                  <input
                    class="form-control"
                    type="time"
                    v-model="newWarehouse.shop.close_monday"
                  />
                </div>
                <div class="col-6 mb-3 whitebg-input">
                  <label class="form-label bold-12 text-gray">{{
                    $t("warehouses.tuesdayOpen")
                  }}</label>
                  <input
                    class="form-control"
                    type="time"
                    v-model="newWarehouse.shop.open_tuesday"
                  />
                </div>
                <div class="col-6 mb-3 whitebg-input">
                  <label class="form-label bold-12 text-gray">{{
                    $t("warehouses.tuesdayClose")
                  }}</label>
                  <input
                    class="form-control"
                    type="time"
                    v-model="newWarehouse.shop.close_tuesday"
                  />
                </div>
                <div class="col-6 mb-3 whitebg-input">
                  <label class="form-label bold-12 text-gray">{{
                    $t("warehouses.wednesdayOpen")
                  }}</label>
                  <input
                    class="form-control"
                    type="time"
                    v-model="newWarehouse.shop.open_wednesday"
                  />
                </div>
                <div class="col-6 mb-3 whitebg-input">
                  <label class="form-label bold-12 text-gray">{{
                    $t("warehouses.wednesdayClose")
                  }}</label>
                  <input
                    class="form-control"
                    type="time"
                    v-model="newWarehouse.shop.close_wednesday"
                  />
                </div>
                <div class="col-6 mb-3 whitebg-input">
                  <label class="form-label bold-12 text-gray">{{
                    $t("warehouses.thursdayOpen")
                  }}</label>
                  <input
                    class="form-control"
                    type="time"
                    v-model="newWarehouse.shop.open_thursday"
                  />
                </div>
                <div class="col-6 mb-3 whitebg-input">
                  <label class="form-label bold-12 text-gray">{{
                    $t("warehouses.thursdayClose")
                  }}</label>
                  <input
                    class="form-control"
                    type="time"
                    v-model="newWarehouse.shop.close_thursday"
                  />
                </div>
                <div class="col-6 mb-3 whitebg-input">
                  <label class="form-label bold-12 text-gray">{{
                    $t("warehouses.fridayOpen")
                  }}</label>
                  <input
                    class="form-control"
                    type="time"
                    v-model="newWarehouse.shop.open_friday"
                  />
                </div>
                <div class="col-6 mb-3 whitebg-input">
                  <label class="form-label bold-12 text-gray">{{
                    $t("warehouses.fridayClose")
                  }}</label>
                  <input
                    class="form-control"
                    type="time"
                    v-model="newWarehouse.shop.close_friday"
                  />
                </div>
                <div class="col-6 mb-3 whitebg-input">
                  <label class="form-label bold-12 text-gray">{{
                    $t("warehouses.saturdayOpen")
                  }}</label>
                  <input
                    class="form-control"
                    type="time"
                    v-model="newWarehouse.shop.open_saturday"
                  />
                </div>
                <div class="col-6 mb-3 whitebg-input">
                  <label class="form-label bold-12 text-gray">{{
                    $t("warehouses.saturdayClose")
                  }}</label>
                  <input
                    class="form-control"
                    type="time"
                    v-model="newWarehouse.shop.close_saturday"
                  />
                </div>
                <div class="col-6 mb-3 whitebg-input">
                  <label class="form-label bold-12 text-gray">{{
                    $t("warehouses.sundayOpen")
                  }}</label>
                  <input
                    class="form-control"
                    type="time"
                    v-model="newWarehouse.shop.open_sunday"
                  />
                </div>
                <div class="col-6 mb-3 whitebg-input">
                  <label class="form-label bold-12 text-gray">{{
                    $t("warehouses.sundayClose")
                  }}</label>
                  <input
                    class="form-control"
                    type="time"
                    v-model="newWarehouse.shop.close_sunday"
                  />
                </div>
                <div class="col-12 mb-3 whitebg-input">
                  <label class="form-label bold-12 text-gray">
                    {{ $t("warehouses.cassaEndpoint") }}
                  </label>
                  <input
                    class="form-control"
                    v-model="newWarehouse.shop.cassa_endpoint"
                  />
                </div>
                <div class="col-12 mb-3 whitebg-input">
                  <label class="form-label bold-12 text-gray">{{
                    $t("warehouses.wireTransferIban")
                  }}</label>
                  <input
                    class="form-control"
                    v-model="newWarehouse.shop.iban"
                  />
                </div>
                <div class="col-4 mb-3 whitebg-input">
                  <label class="form-label bold-12 text-gray">{{
                    $t("warehouses.wireTransferBic")
                  }}</label>
                  <input class="form-control" v-model="newWarehouse.shop.bic" />
                </div>
                <div class="col-8 mb-3 whitebg-input">
                  <label class="form-label bold-12 text-gray">{{
                    $t("warehouses.wireTransferName")
                  }}</label>
                  <input
                    class="form-control"
                    v-model="newWarehouse.shop.bank_holder_name"
                  />
                </div>
                <div class="mb-3 whitebg-input">
                  <label class="form-label bold-12 text-gray">{{
                    $t("orderChange.country")
                  }}</label>
                  <v-select
                    :options="activeCountries"
                    v-model="newWarehouse.shop.country_id"
                    label="name"
                    :placeholder="$t(`orderChange.selectCountry`)"
                    :reduce="(c) => c.id"
                  />
                </div>
                <div class="row mb-3 custom-input">
                  <div class="col-4 whitebg-input">
                    <label class="form-label bold-12 text-gray">{{
                      $t("orderChange.postCode")
                    }}</label>
                    <input
                      type="text"
                      v-model="newWarehouse.shop.post_code"
                      class="form-control"
                    />
                  </div>
                  <div class="col whitebg-input">
                    <label class="form-label bold-12 text-gray">{{
                      $t("orderChange.city")
                    }}</label>
                    <input
                      type="text"
                      v-model="newWarehouse.shop.city"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="row mb-3 whitebg-input">
                  <div class="col">
                    <label class="form-label bold-12 text-gray">{{
                      $t("warehouses.street")
                    }}</label>
                    <input
                      type="text"
                      v-model="newWarehouse.shop.street"
                      class="form-control"
                    />
                  </div>
                  <div class="col-5 whitebg-input">
                    <label class="form-label bold-12 text-gray">{{
                      $t("warehouses.house")
                    }}</label>
                    <input
                      type="text"
                      v-model="newWarehouse.shop.house"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>
              <div class="row" v-if="openTab === 'download'">
                <div class="row mb-3 custom-input">
                  <div class="col-12 mb-3 whitebg-input">
                    <label class="form-label bold-12 text-gray">{{
                      $t("warehouses.currency")
                    }}</label>
                    <v-select
                      v-model="download.currency"
                      :options="currencies"
                      label="code"
                      :reduce="(c) => c.code"
                      :getOptionKey="(c) => c.code"
                      :placeholder="$t(`warehouses.selectACurrency`)"
                      :clearable="false"
                    />
                  </div>
                  <div class="col-12 row mt-4">
                    <label class="cstm-check d-flex">
                      <span class="regular-12 text-black mx-4">
                        {{ $t("products.products") }}
                      </span>
                      <input type="checkbox" v-model="download.products" />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div class="col-12 row mt-4">
                    <label class="cstm-check d-flex">
                      <span class="regular-12 text-black mx-4">
                        {{ $t("products.materials") }}
                      </span>
                      <input type="checkbox" v-model="download.materials" />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
                <button
                  v-on:click="downloadXlsx(newWarehouse.id)"
                  class="btn btn-primary"
                >
                  {{ $t("warehouses.downloadXlsx") }}
                </button>
              </div>
              <div class="row" v-if="openTab === 'fulfillment'">
                <div class="row mb-3 custom-input">
                  <div class="col-12 row mt-4">
                    <label class="cstm-check d-flex">
                      <span class="regular-12 text-black mx-4">
                        {{ $t("warehouses.autoPrepaid") }}
                      </span>
                      <input
                        type="checkbox"
                        value="1"
                        v-model="newWarehouse.fulfillment_auto_delivery_prepaid"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div class="col-12 row mt-4">
                    <label class="cstm-check d-flex">
                      <span class="regular-12 text-black mx-4">
                        {{ $t("warehouses.autoCod") }}
                      </span>
                      <input
                        type="checkbox"
                        value="1"
                        v-model="newWarehouse.fulfillment_auto_delivery_cod"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div class="col-12 mb-3 whitebg-input mt-3">
                    <label class="form-label bold-12 text-gray">{{
                      $t("warehouses.autoFraudRiskLimit")
                    }}</label>
                    <div class="fix-end-input">
                      <input
                        type="number"
                        class="form-control"
                        v-model="
                          newWarehouse.fulfillment_auto_delivery_fraud_risk_limit
                        "
                        placeholder="50"
                      />
                      <span>%</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              v-on:click="saveWarehouse"
              :class="{ 'loading-spin': loadAddBtn }"
              :disabled="loadAddBtn"
              v-if="editWarehouse"
            >
              {{ $t("orderChange.save") }}
            </button>
            <button
              type="button"
              class="btn btn-primary"
              v-on:click="addWarehouse"
              :class="{ 'loading-spin': loadAddBtn }"
              :disabled="loadAddBtn"
              v-else
            >
              {{ $t("orderChange.save") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ConfirmModal
    ref="deleteModal"
    :title="$t(`warehouses.deleteWarehouse`)"
    :text="`<ul><li>You will delete ${deleteObject?.name}<b></li><li>The warehouse needs to be empty</li><li>You can't undo this action</li></ul><p>Are you sure you want to delete?</p>`"
    :cancel-button="`No, keep ${deleteObject?.name}`"
    :ok-button="`Yes, delete ${deleteObject?.name}`"
    v-on:success="deleteWarehouse"
  />
</template>

<script>
import OneByOneTable from "../lists/OneByOneTable";
import http from "../../modules/http";
import { useStore } from "vuex";
import ConfirmModal from "../modals/ConfirmModal";
import BaseIcon from "../icons/BaseIcon";
import ImageUpload from "../inputs/ImageUpload";
import store from "@/store";
const bootstrap = require("bootstrap");

export default {
  name: "Warehouses",
  components: { ImageUpload, BaseIcon, ConfirmModal, OneByOneTable },
  data() {
    return {
      openTab: "basic",
      loadAddBtn: false,
      newWarehouse: {
        shop: {},
      },
      deleteObject: {},
      download: {
        currency: null,
        products: true,
        materials: true,
      },
      editWarehouse: false,
      filterCountry: null,
      countries: [],
      companies: [],
      invoiceMethods: [],
      deliveryMethods: [],
      rows: [
        {
          name: "Name",
          key: "name",
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "Company",
          key: "company_id",
          type: "enum",
          values: undefined,
          selectKey: "id",
          label: "name",
          show: true,
          editable: false,
        },
        {
          name: "Address",
          key: "address",
          type: "address",
          show: true,
          editable: false,
        },
      ],
      warehouses: undefined,
      addWindow: null,
    };
  },
  mounted() {
    this.load();
  },
  computed: {
    mediaUrl() {
      return process.env.VUE_APP_MEDIA_URL;
    },
    activeCountries() {
      const store = useStore();
      return store.state.countries;
    },
    warehouseCountries() {
      let countries = [];
      if (this.warehouses === undefined) {
        return [];
      }
      for (const warehouse of this.warehouses) {
        if (countries.indexOf(warehouse.address.country_id) === -1) {
          countries.push(warehouse.address.country_id);
        }
      }
      return countries;
    },
    currencies() {
      const store = useStore();
      return store.state.currencies;
    },
  },
  methods: {
    downloadXlsx(id) {
      window.open(
        `${process.env.VUE_APP_API}/warehouses/stock/${id}/${
          this.download.currency
        }/${this.download.products ? 1 : 0}-${
          this.download.materials ? 1 : 0
        }/{type}?_token=${store.state.token}`,
        "_blank"
      );
    },
    downloadBarcodes(id) {
      window.open(
        `${process.env.VUE_APP_API}/warehouses/barcode/${id}?_token=${store.state.token}`,
        "_blank"
      );
    },
    editAddress(prop) {
      if (this.newWarehouse.id > 0) {
        this.newWarehouse.address[prop] = this.newWarehouse[prop];
      }
    },

    load() {
      http.fetch("/companies").then((data) => {
        this.rows[1].values = data;
        this.companies = data;
      });
      http.fetch("/warehouses").then((data) => {
        this.warehouses = data;
      });
    },
    addWarehouse() {
      this.loadAddBtn = true;
      http
        .fetch("/warehouses", this.newWarehouse, true)
        .then((data) => {
          this.addWindow.hide();
          this.loadAddBtn = false;
          this.warehouses.push(data);
        })
        .catch(() => {
          this.loadAddBtn = false;
        });
    },
    saveWarehouse() {
      this.loadAddBtn = true;
      let data = Object.assign({}, this.newWarehouse);
      data.fulfillment_auto_delivery_prepaid =
        data.fulfillment_auto_delivery_prepaid ? 1 : 0;
      data.fulfillment_auto_delivery_cod = data.fulfillment_auto_delivery_cod
        ? 1
        : 0;

      delete data.id;
      http
        .fetch("/warehouses/" + this.newWarehouse.id, data, true, "PUT")
        .then(() => {
          this.addWindow.hide();
          this.loadAddBtn = false;
        })
        .catch(() => {
          this.loadAddBtn = false;
        });
    },
    deleteWarehouse() {
      http
        .fetch("/warehouses/" + this.deleteObject.id, {}, true, "DELETE")
        .then(() => {
          let i = this.warehouses.indexOf(this.deleteObject);
          if (i > -1) {
            this.warehouses.splice(i, 1);
          }
        });
    },
    showDeleteModal(element) {
      this.deleteObject = element;
      this.$refs.deleteModal.showModal();
    },
    showEditModal(id) {
      this.editWarehouse = true;
      let data = this.warehouses.filter((e) => e.id === id)[0];
      data = Object.assign(data, data.address);
      if (data.shop === null) {
        data.shop = { enabled: false };
      } else {
        data.shop.enabled = true;
      }
      this.newWarehouse = data;
      this.download.currency = data.default_currency;

      if (this.countries.length === 0) {
        http.fetch("/countries/all").then((data) => {
          this.countries = data;
        });
      }
      if (this.companies.length === 0) {
        http.fetch("/companies").then((data) => {
          this.companies = data;
        });
      }
      if (this.invoiceMethods.length === 0) {
        http.fetch("/invoices/methods").then((data) => {
          this.invoiceMethods = data;
        });
      }
      if (this.deliveryMethods.length === 0) {
        http.fetch("/deliveries/methods").then((data) => {
          this.deliveryMethods = data;
        });
      }

      this.addWindow = new bootstrap.Modal(this.$refs.addWarehouseModal, {
        backdrop: "static",
      });
      this.addWindow.show();
    },
    uploadImage(file) {
      this.newWarehouse.shop.image_id = file.id;
      this.newWarehouse.shop.image = file;
    },
    showAddModal() {
      this.editWarehouse = false;
      this.newWarehouse = {};
      if (this.countries.length === 0) {
        http.fetch("/countries/all").then((data) => {
          this.countries = data;
        });
      }
      if (this.companies.length === 0) {
        http.fetch("/companies").then((data) => {
          this.companies = data;
        });
      }
      if (this.invoiceMethods.length === 0) {
        http.fetch("/invoices/methods").then((data) => {
          this.invoiceMethods = data;
        });
      }
      if (this.deliveryMethods.length === 0) {
        http.fetch("/deliveries/methods").then((data) => {
          this.deliveryMethods = data;
        });
      }
      this.addWindow = new bootstrap.Modal(this.$refs.addWarehouseModal, {
        backdrop: "static",
      });
      this.addWindow.show();
    },
  },
};
</script>
